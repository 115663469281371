import image1 from '../assets/images/hasan5gvip.png';
const siteOptionData = [
    {
      id: 1,
      siteName: "HASAN 5G BRAND",
      image: image1,
      description: "খুব সহজে ডাউনলোড করতে পারবেন। নিচে লিংক এ ক্লিক করেন &  পুরো ভিডিও দেখেন.",
      siteLink: "https://youtu.be/iUT8mMssvGQ?si=5T3UcXxU5LHqB1po"
    }
  ];
  export default siteOptionData;